
.navbar {
    font-weight: bold;
}

@media (min-width: $screen-sm-min) {
    .transparent-nav {
        .navbar {
            background: none;
            border-color: transparent;
            color: white;
            text-shadow: 0 0 5px rgba(black, 0.8);
        }

        .navbar a,
        .navbar-default .navbar-nav > li > a {
            color: inherit;

            &:hover {
                color: #333;
                background: white;
                border-radius: 2px;
                text-shadow: none;
            }
        }

        .navbar .dropdown.open a {
            text-shadow: none;
        }

        .dropdown-menu > li > a {
            color: #333;
            text-shadow: none;
        }
    }
}

.box-top {
    background: #3498db;
    padding-top: 20px;

    .box-title {
        background: #2980b9;
        border: 1px solid darken(#2980b9, 5%);
        border-bottom: none;
        border-radius: 2px 2px 0 0;
        color: white;
        padding-top: 20px;
        padding-bottom: 20px;

        h2 {
            margin: 0;
        }

        .nav a {
            color: white;
            font-weight: bold;
            padding: 10px 8px;
        }

        .nav .active a,
        .nav a:hover {
            color: #555555;
        }
    }
}

.box-bottom {
    background: #efefef;
    padding-bottom: 20px;

    .box-content {
        background: white;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 2px 2px;
    }
}

.box-item {
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(.last-item) {
        border-bottom: 2px solid #efefef;
    }

    &.box-note {
        text-align: center;
        color: #aaa;
    }
}

footer {
    background: #444;
    padding: 20px 0 50px;
    color: white;
    text-align: center;

    a, a:hover {
        color: inherit;
    }
}
