
.banner-account {
    background-image: url(/img/carottes.jpg);
    background-size: cover;
    background-position: center top;

    h1 {
        color: white;
    }

    .box-top {
        background: none;

        .box-title {
            background-color: #d35400;
            border-color: darken(#d35400, 5%);
        }
    }
}

.banner-order,
.banner-basket {
    background-image: url(/img/choux.jpg);
    background-size: cover;
    background-position: center bottom;

    .box-top {
        background: none;

        .box-title {
            background: #3c6c0e;
            border-color: darken(#3c6c0e, 5%);
        }
    }
}
