
.order-summary {
    color: white;
    text-shadow: 0 0 5px rgba(black, 0.5);

    .alert {
        text-shadow: none;
    }

    h1 {
        font-weight: bold;
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .btn-order-action.btn {
        display: block;
        margin: 0 auto;
        border: 2px solid white;
        border-radius: 2px;
        font-weight: bold;
        font-size: 1.2em;
        background: rgba(white, 0.1);

        &:hover {
            background: white;
            color: #333;
            text-shadow: none;
        }
    }

    .btn-order-action.btn.btn-danger {
        border-color: #9b1900;
        background: rgba(#9b1900, 0.1);
        margin-top: 10px;

        &:hover {
            background: #9b1900;
            color: white;
        }
    }

    .order-progress {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        min-height: 100px;

        .progress-current {
            flex-grow: 1;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                border-top: 2px dotted white;
            }

            .progress-pointer {
                display: block;
                position: absolute;
                bottom: 20px;
                width: 16px;
                height: 16px;
                border-radius: 100%;
                background: white;
                margin-left: -8px;
                margin-bottom: -8px;

                text-indent: -9999px;
                overflow: hidden;
            }
        }

        .progress-point {
            position: relative;
            height: 100px;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                height: 50px;
                border-right: 1px solid white;
            }

            .progress-label {
                position: absolute;
                right: 10px;
                min-width: 180px;
                text-align: right;

                strong {
                    display: block;
                    font-size: 1.2em;
                }
            }

            .fa {
                position: absolute;
                bottom: 5px;
                right: -0.5em;
                font-size: 30px;
            }
        }

        .progress-last {
            width: 200px;

            &::before {
                content: '';
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                border-top: 2px dotted white;
            }
        }

        .progress-first {
            .progress-label {
                left: 10px;
                right: auto;
                text-align: left;
            }
        }
    }

    .in-basket {
        text-align: center;
        font-size: 3em;

        .order-products,
        .order-total {
            display: block;
        }
    }

    .cart-actions {
        text-align: center;
    }
}

.section-products {
    .box-top {
        .nav-tabs {
            border: none;

            a,
            a:hover,
            a:focus {
                border: none;
                border-radius: 4px;
            }

            .badge {
                background: white;
                color: #2980b9;
            }

            li.active .badge {
                background: #2980b9;
                color: white;
            }
        }
    }
}

.label-tag {
    font-size: 0.9em;
    display: inline-block;
    padding: 0.1em 0.3em;
    background: #efefef;
    border-radius: 2px;
    margin-right: 0.3em;
    font-weight: bold;
    color: #666;
}

.label-icon {
    display: inline-block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 1.3em;
    height: 1em;
    vertical-align: middle;
}

.label-icon-ch { background-image: url(/img/flags/1x1/ch.svg); width: 1em; }
.label-icon-de { background-image: url(/img/flags/4x3/de.svg); }
.label-icon-do { background-image: url(/img/flags/4x3/do.svg); }
.label-icon-es { background-image: url(/img/flags/4x3/es.svg); }
.label-icon-eg { background-image: url(/img/flags/4x3/eg.svg); }
.label-icon-fr { background-image: url(/img/flags/4x3/fr.svg); }
.label-icon-it { background-image: url(/img/flags/4x3/it.svg); }

.label-icon-bourgeon {
    background-image: url(/img/bourgeon.gif);
    width: 1.3em;
}

.label-icon-bioeurope {
    background-image: url(/img/bioeurope.svg);
    width: 1.5em;
}

.label-icon-demeter {
    background-image: url(/img/demeter.gif);
    width: 2em;
}

.label-icon-ju {
    background-image: url(/img/jura.svg);
    width: 0.8em;
}
