
.banner-home {
    position: relative;
    overflow: hidden; // Quand la vidéo dépasse sur les résolutions plus petites

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #3c6c0e;
        background-image: url(/img/panier_still.jpg);
        background-size: cover;
        background-position: center top;
    }

    .landing-video {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;

        @media (max-width: 1400px) {
            left: -10%;
            width: 120%;
        }

        @media (max-width: 1000px) {
            left: -20%;
            width: 140%;
        }

        @media (max-width: $screen-sm-min) {
            top: auto;
            bottom: 0; // Éviter d'être sous le menu ou les popups
        }
    }
}

.section-landing {
    text-align: center;
    color: white;
    text-shadow: 0 0 5px rgba(black, 0.8);

    .catchy-text {
        font-weight: bold;
        font-size: 1.5em;
        width: 900px;
        max-width: 100%;
        margin: 0 auto 20px;
    }

    .landing-btn,
    .landing-btn-disabled {
        display: inline-block;
        border: 5px solid white;
        color: white;
        border-radius: 5px;
        padding: 0.5em 2em;
        font-size: 1.5em;
        background: rgba(white, 0.1);
    }

    .landing-btn:hover {
        background: white;
        color: #333;
        text-decoration: none;
        text-shadow: none;
    }

    .landing-btn-disabled {
        border-color: rgba(white, 0.3);
    }

    .landing-more {
        margin-top: 30px;
        font-size: 1.2em;
    }

    .alert {
        text-shadow: none;
    }

    @media (min-width: $screen-xs-min) {
        padding-top: 30px;
        padding-bottom: 30px;

        .catchy-text {
            margin-bottom: 80px;
        }
    }

    @media (min-width: $screen-sm-min) {
        .catchy-text {
            font-size: 3em;
        }

        .landing-btn,
        .landing-btn-disabled {
            font-size: 2.5em;
        }
    }

    @media (min-width: $screen-lg-min) {
        padding-top: 100px;

        .landing-more {
            margin-top: 250px;
        }
    }
}

.section-howto {
    background: #2c3e50;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;

    .howto-block {
        text-align: center;
        font-size: 1.5em;
        margin-top: 30px;
        margin-bottom: 50px;

        .howto-item {
            border: 2px solid white;
            border-radius: 2px;
            padding: 60px 10px;
            margin-bottom: 20px;

            .fa {
                font-size: 70px;
                margin-bottom: 20px;
            }

            p {
                width: 250px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }

    .howto-note {
        text-align: center;
        font-size: 1.5em;
        width: 900px;
        max-width: 100%;
        margin: 0 auto;
    }

    a {
        color: #aaa;
    }
}

.section-about {
    .box-top {
        background: #2c3e50;
    }

    @media (min-width: $screen-md-min) {
        // Afficher les titres dans la zone de titre au-dessus

        .box-title {
            min-height: 5em;
        }

        .box-content {
            h2 {
                position: relative;
                top: -2.3em;
                color: white;
            }
        }
    }

    article {
        margin-bottom: 30px;
        padding-bottom: 20px;

        &:not(:last-of-type) {
            border-bottom: 2px solid #eee;
        }

        .date {
            margin-bottom: 0;
            color: #aaa;
        }
    }
}
